
import { defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import moment, { Moment } from "moment";
import { confirmDeadline } from "@/API/checking/demoCarClaim";
export default defineComponent({
  props: {
    confirmVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    confirmData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const modal = (props as any).confirmData;
    const submissionMode = ref<string[]>(["date"]);
    const adjustedSubmissionDeadline = ref<any>();
    const adjustedSubmissionChange = (val: Moment[]) => {
      //console.log(val);
      adjustedSubmissionDeadline.value = val;
    };

    const close = () => {
      emit("close");
    };
    const confirm = () => {
      if (modal.comment === "") {
        message.error("comment can't be null");
        close();
      } else {
        const params = {
          adjustedSubmissionDeadline: modal.adjustedSubmissionDeadline,
          comment: modal.comment,
          id: modal.id,
        };
        confirmDeadline(params).then((res) => {
          close();
        });
      }
    };
    return {
      modal,
      submissionMode,
      adjustedSubmissionDeadline,
      adjustedSubmissionChange,
      close,
      confirm,
    };
  },
});
