
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    communicateVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    communicateData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    communicateDataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ["close", "confirm"],
  setup(props, { emit }) {
    const modal = (props as any).communicateData;

    const columns = [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        width: 100,
      },
      {
        title: "VIN",
        dataIndex: "vin",
        width: 150,
      },
      {
        title: "Submission Deadline",
        dataIndex: "submissionDeadline",
        width: 150,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        width: 150,
      },
    ];

    const close = () => {
      emit("close");
    };

    const confirm = () => {
      if (modal.comment === "") {
        message.error("comment can't be null");
        close();
      } else {
        emit("confirm");
      }
    };
    return {
      close,
      confirm,
      columns,
      modal,
    };
  },
});
