
import { createVNode, ref, defineComponent } from "vue";
import { FileItem } from "@/API/types";
import { message } from "ant-design-vue";
import { uploadDemoCar, errorDownload } from "@/API/checking/demoCarClaim";
import { Modal } from "ant-design-vue";
import { downloadFromUrl } from "@/utils/payment/downloadFile";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  props: {
    uploadVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ["fresh-source-data", "close"],
  setup(prop, { emit }) {
    const formData = new FormData();

    //两个下拉框的值
    const isBeforeUpload = ref<boolean>(true);
    // 进度条
    const isShowUploadBtn = ref<boolean>(true);
    const isClickAbled = ref<boolean>(true);

    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0);
    const uploadFileSize = ref<string>("");
    const completeTips = ref<boolean>(false);

    //重传
    const handelBrowse = () => {
      curPercent.value = 0;
      uploadFileSize.value = "";
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      if (formData.has("document")) {
        formData.delete("document");
      }
    };

    //关闭抽屉的回调
    const afterVisibleChange = () => {
      curPercent.value = 0;
      uploadFileSize.value = "";
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      if (formData.has("document")) {
        formData.delete("document");
      }
    };
    const loading = ref<boolean>(false);

    // 上传前
    const beforeUpload = (file: FileItem) => {
      let format = true;
      if (
        !(
          file.type === "image/jpeg" ||
          file.type === "application/pdf" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        message.error("Only xlsx/pdf/jpg format files can be uploaded!");
        format = false;
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("The file size should not be larger than 10MB!");
      }
      return format && isLt5M;
    };

    const uploadRequest = (source: any) => {
      if (formData.has("document")) {
        formData.delete("document");
      }
      formData.append("document", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      isBeforeUpload.value = false;
    };

    // 上传文件
    const uploadFile = (source: any) => {
      isClickAbled.value = false;
      uploadDemoCar(formData)
        .then((res: any) => {
          isShowUploadBtn.value = false;
          curPercent.value = 100;
          completeTips.value = true;
          isClickAbled.value = true;
          if (res.data) {
            Modal.confirm({
              title: "Confirm",
              icon: createVNode(ExclamationCircleOutlined),
              content: "Data validation failed.",
              okText: "Download",
              cancelText: "Cancel",
              onOk: async () => {
                const { downloadUrl } = await errorDownload(res.data);
                downloadFromUrl(downloadUrl);
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    //关闭抽屉事件
    const closeDrawer = () => {
      emit("close");
    };

    //关闭抽屉
    const cancel = () => {
      closeDrawer();
    };

    const spinWrapper = document.querySelector(
      ".spingWrapper .ant-spin-container"
    );
    return {
      uploadFile,
      loading,
      handelBrowse,
      isShowUploadBtn,
      curUploadFile,
      curPercent,
      uploadFileSize,
      completeTips,
      isClickAbled,
      isBeforeUpload,
      beforeUpload,
      uploadRequest,
      afterVisibleChange,
      closeDrawer,
      cancel,
      spinWrapper,
    };
  },
});
