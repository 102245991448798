
interface SelectItem {
  itemName: string;
  itemCode: string;
}

interface SearchDataType {
  dealerCode: string | null;
  dealerName: string | null;
  dealerNameEn: string | null;
  vinList: string[];
  confirmStatus: string[] | null;
  vin: string | null;
  displayClaimId: string | null;
  programCode: string | null;
  typeClass: string | null;
  modelYear: string | null;
  model: string | null;
  startDate: Moment | null;
  endDate: Moment | null;
  holdingDateFrom: Moment | null;
  holdingDateTo: Moment | null;
  submissionDeadlineFrom: Moment | null;
  submissionDeadlineTo: Moment | null;
  operationTimeFrom: Moment | null;
  operationTimeTo: Moment | null;
  id: number;
}

interface FileListType {
  id: string;
  fileName: string;
  source: string;
}

interface batchConfirmList {
  dealerCode: string;
  submissionDeadline: string;
  comment: string;
  //adjustedSubmissionDeadline: string;
  vin: string;
  idList: string[];
  id: string;
}
import { defineComponent, nextTick, reactive, ref, toRefs, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import uploadAdjustedFile from './uploadAdjustedFile/index.vue';
import confirmModal from '@/views/Checking/components/confirmModal.vue';
import batchConfirmModal from '@/views/Checking/components/batchConfirmModal.vue';
import communicateModal from '@/views/Checking/components/communicateModal.vue';
import {
  getFindByStatus,
  getDemoCarList,
  exportPageApi,
  getConfirmMsg,
  getBatchConfirmMsg,
  getCommunicateMsg,
  communicateMail,
  withdrawDemoCar
} from '@/API/checking/demoCarClaim';
import { getTypeClass, getModelYear } from '@/API/salesReporting/claimCheckingReport';
export default defineComponent({
  components: {
    uploadAdjustedFile,
    confirmModal,
    batchConfirmModal,
    communicateModal
  },
  setup() {
    const confirmStatusArray = ref<SelectItem[]>([]);
    //获取status下拉框参数
    const getSelectStatus = () => {
      getFindByStatus().then((res) => {
        console.log(res);
        const arr = [];
        for (let i = 0; i < res.length; i++) {
          arr.push({
            itemName: res[i].name,
            itemCode: res[i].code
          });
        }
        confirmStatusArray.value = [];
        confirmStatusArray.value = arr;
      });
    };
    // 批量查询VIN
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });
    //Modal的输入框
    const textContentNo = ref<string>('');
    //输入框，用于控制焦点
    const input = ref();
    //显示的VIN数组
    const textNoArray = ref<string[]>([]);
    //空占位
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    const cleanEmptyEleArray = (array: Array<string>) => {
      if (array.length > 1) {
        const tempArray = array.filter((item) => item !== '');
        tempArray.length || tempArray.push('');
        return tempArray;
      } else {
        return array;
      }
    };
    // 设置批量查询modal的占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询VIN按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = 'VIN No. Query';
      batchQuery.batchQueryListTitle = 'VIN No. List';
      textNoArray.value =
        cleanEmptyEleArray(searchData.vinList)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinList);
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('Please paste into the text box from excel');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g, ',').split(','));
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      searchData.vinList = [];
      searchData.vinList = searchData.vinList.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchData.vin = cleanEmptyEleArray(searchData.vinList)[0] as string;
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchData.vinList = [''];
      textNoArray.value =
        cleanEmptyEleArray(searchData.vinList)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinList);
      setBatchPlaceholder();
      searchData.vin = null;
      input.value.focus();
    };
    //查询内容
    const searchData = reactive<SearchDataType>({
      dealerCode: null,
      dealerName: null,
      dealerNameEn: null,
      vinList: [],
      confirmStatus: [],
      vin: '',
      displayClaimId: null,
      programCode: null,
      typeClass: null,
      modelYear: null,
      model: null,
      startDate: null,
      endDate: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      submissionDeadlineFrom: null,
      submissionDeadlineTo: null,
      operationTimeFrom: null,
      operationTimeTo: null,
      id: 0
    });
    const searchDataTemporary = reactive<SearchDataType>({
      dealerCode: null,
      dealerName: null,
      dealerNameEn: null,
      vinList: [],
      confirmStatus: [],
      vin: '',
      displayClaimId: null,
      programCode: null,
      typeClass: null,
      modelYear: null,
      model: null,
      startDate: null,
      endDate: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      submissionDeadlineFrom: null,
      submissionDeadlineTo: null,
      operationTimeFrom: null,
      operationTimeTo: null,
      id: 0
    });

    const holdingPeriodChange = (val: Moment[]) => {
      searchData.holdingDateFrom = val[0];
      searchData.holdingDateTo = val[1];
    };

    const submissionChange = (val: Moment[]) => {
      searchData.submissionDeadlineFrom = val[0];
      searchData.submissionDeadlineTo = val[1];
    };

    const operationTimeChange = (val: Moment[]) => {
      searchData.operationTimeFrom = val[0];
      searchData.operationTimeTo = val[1];
    };

    // type class
    const typeClassList = ref([]);
    getTypeClass().then((res: any) => {
      typeClassList.value = res;
    });

    const modelYearList = ref([]);
    const getModleYear = () => {
      getModelYear().then((res) => {
        const dataArr = ref<any>([]);
        res.forEach((i: any, ind: any) => {
          if (i !== null) {
            dataArr.value.push(i);
          }
        });
        modelYearList.value = dataArr.value;
      });
    };
    getModleYear();
    const getParams = () => {
      const params = {
        //TODO 联调的时候修改参数
        current: pagination.current,
        size: pagination.pageSize,
        confirmStatus: searchDataTemporary.confirmStatus,
        dealerCode: searchDataTemporary.dealerCode,
        dealerName: searchDataTemporary.dealerName,
        dealerNameEn: searchDataTemporary.dealerNameEn,
        programCode: searchDataTemporary.programCode,
        holdingDateFrom: searchDataTemporary.holdingDateFrom,
        holdingDateTo: searchDataTemporary.holdingDateTo,
        submissionDeadlineFrom: searchDataTemporary.submissionDeadlineFrom,
        submissionDeadlineTo: searchDataTemporary.submissionDeadlineTo,
        operationTimeFrom: searchDataTemporary.operationTimeFrom,
        operationTimeTo: searchDataTemporary.operationTimeTo,
        vinList:
          searchDataTemporary.vinList.length === 1 && searchDataTemporary.vinList[0] === ''
            ? []
            : searchDataTemporary.vinList,
        vin:
          searchDataTemporary.vinList.length >= 1 && searchDataTemporary.vinList[0] !== ''
            ? ''
            : searchDataTemporary.vin,
        displayClaimId: searchDataTemporary.displayClaimId ?? null,
        model: searchDataTemporary.model,
        modelYear: searchDataTemporary.modelYear,
        typeClass: searchDataTemporary.typeClass
      };
      return params;
    };

    //重置
    const clickResetEvent = () => {
      searchData.dealerCode = null;
      searchData.dealerName = null;
      searchData.dealerNameEn = null;
      searchData.vinList = [''];
      searchData.confirmStatus = [];
      searchData.vin = null;
      searchData.displayClaimId = null;
      searchData.programCode = null;
      searchData.typeClass = null;
      searchData.modelYear = null;
      searchData.model = null;
      searchData.holdingDateFrom = null;
      searchData.holdingDateTo = null;
      searchData.submissionDeadlineFrom = null;
      searchData.submissionDeadlineTo = null;
      searchData.operationTimeFrom = null;
      searchData.operationTimeTo = null;

      Object.assign(searchDataTemporary, searchData);
    };

    const dataSource = ref<any>([]);
    const columns = [
      {
        title: 'Claim ID',
        dataIndex: 'displayClaimId',
        key: 'displayClaimId',
        width: 200,
        ellipsis: true
      },
      {
        title: 'Dealer Name(CN)',
        dataIndex: 'dealerName',
        key: 'dealerName',
        width: 240
      },
      {
        title: 'Dealer Name(EN)',
        dataIndex: 'dealerNameEn',
        key: 'dealerNameEn',
        width: 240
      },
      {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
        width: 200
      },
      {
        title: 'Holding Period DDL',
        dataIndex: 'holdingDate',
        key: 'holdingDate',
        slots: { customRender: 'holdingPeriod' },
        width: 180
      },
      {
        title: 'Type Class',
        dataIndex: 'typeClass',
        key: 'typeClass',
        width: 120
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: 200
      },
      {
        title: 'Model Year',
        dataIndex: 'modelYear',
        key: 'modelYear',
        width: 120
      },
      {
        title: 'Status',
        dataIndex: 'confirmStatus',
        key: 'confirmStatus',
        width: 140
      },
      {
        title: 'Check Result',
        dataIndex: 'checkResult',
        key: 'checkResult',
        width: 140
      },
      {
        title: 'Submission Deadline',
        dataIndex: 'submissionDeadline',
        key: 'submissionDeadline',
        slots: { customRender: 'submissionDeadline' },
        width: 180
      },
      {
        title: 'Adjusted Submission Deadline',
        dataIndex: 'adjustedSubmissionDeadline',
        key: 'adjustedSubmissionDeadline',
        slots: { customRender: 'adjustedSubmission' },
        width: 200
      },

      {
        title: 'Program Code',
        dataIndex: 'programCode',
        key: 'programCode',
        width: 180
      },

      {
        title: 'Operation Time',
        dataIndex: 'operationTime',
        key: 'operationTime',
        slots: { customRender: 'operationTime' },
        width: 180
      },
      {
        title: 'Operation',
        fixed: 'right',
        width: 200,
        slots: { customRender: 'operation' }
      }
    ];

    //查询
    const getTableData = () => {
      const params = getParams();
      getDemoCarList(params).then((res) => {
        console.log(res);
        dataSource.value = res.content;
        state.selectedRowKeys = [];
        pagination.total = res.totalElements;
      });
    };

    //点击查询事件
    const clickSearchEvent = () => {
      pagination.current = 1;
      Object.assign(searchDataTemporary, searchData);
      getTableData();
    };

    type Key = ColumnProps['key'];
    const state = reactive<{
      selectedRowKeys: Key[];
      selectedRows: any[];
      loading: boolean;
    }>({
      selectedRowKeys: [],
      selectedRows: [],
      loading: false
    });
    const onSelectChange = (selectedRowKeys: Key[], selectedRows: any[]) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };

    const confirmData = reactive({});
    const confirmVisible = ref<boolean>(false);
    const confirmParams = reactive({
      adjustedSubmissionDeadline: '',
      submissionDeadline: '',
      comment: '',
      id: ''
    });

    //单条确认弹窗
    const clickConfirm = (record: any) => {
      const id = record.id;
      const params = { id };
      getConfirmMsg(params).then((res) => {
        confirmParams.adjustedSubmissionDeadline = res.adjustedSubmissionDeadline;
        confirmParams.submissionDeadline = res.submissionDeadline;
        confirmParams.comment = res.comment;
        confirmParams.id = res.id;
        Object.assign(confirmData, confirmParams);
        confirmVisible.value = true;
      });
    };

    //Withdraw
    const clickWithdraw = async (record: any) => {
      await withdrawDemoCar(record.id);
      getTableData();
    };

    const batchConfirmVisible = ref<boolean>(false);
    const batchDataSource = ref<batchConfirmList[]>([]);
    const batchConfirmData = reactive({});
    const batchConfirmParams = reactive({
      dealerCode: '',
      submissionDeadline: '',
      comment: '',
      adjustedSubmissionDeadline: '',
      vin: '',
      idList: [0],
      element: 0
    });

    //批量确认弹窗
    const clickBatchConfirm = () => {
      if (state.selectedRows.length === 0) {
        //console.log(state.selectedRows);
        const idList: Array<number> = [];
        const params = { idList, ...getParams() };
        getBatchConfirmMsg(params).then((res) => {
          res.forEach((item: any) => {
            idList.push(item.id);
          });
          batchDataSource.value = res;
          const batchData = res;
          batchConfirmParams.adjustedSubmissionDeadline = batchData.submissionDeadline;
          batchConfirmParams.comment = batchData.comment;
          batchConfirmParams.element = batchData.length;
          batchConfirmParams.idList = idList;
          Object.assign(batchConfirmData, batchConfirmParams);
          console.log(batchConfirmData);
          batchConfirmVisible.value = true;
        });
      } else {
        const idList: Array<number> = [];
        state.selectedRows.forEach((item: SearchDataType) => {
          idList.push(item.id);
        });
        const params = { idList, ...getParams() };
        getBatchConfirmMsg(params).then((res) => {
          //console.log(res);
          batchDataSource.value = res;
          const batchData = res;
          batchConfirmParams.adjustedSubmissionDeadline = batchData.submissionDeadline;
          batchConfirmParams.comment = batchData.comment;
          batchConfirmParams.element = res.length;
          batchConfirmParams.idList = idList;
          //console.log(batchConfirmParams.idList);
          Object.assign(batchConfirmData, batchConfirmParams);
          console.log(batchConfirmData);
          batchConfirmVisible.value = true;
        });
      }
    };

    //文件列表
    const fileList = ref<FileListType[]>([]);
    //子弹窗实例化
    const uploadAdjustedFile = ref<any>(null);
    const uploadVisible = ref<boolean>(false);

    //显示Upload Adjusted Submission Deadline抽屉
    const showUploadAdjustedFile = () => {
      uploadVisible.value = true;
    };
    //关闭upload抽屉
    const closeUploadAdjusted = () => {
      uploadVisible.value = false;
      getTableData();
    };
    //closeUploadAdjusted;

    const communicateVisible = ref<boolean>(false);
    const communicateDataSource = ref<batchConfirmList[]>([]);
    const communicateData = reactive({});
    const communicateParams = reactive({
      comment: '',
      element: 0,
      idList: [0]
    });

    //触发邮件沟通弹窗
    const communicate = () => {
      if (state.selectedRows.length === 0) {
        const idList: Array<number> = [];
        const params = { idList, ...getParams() };
        getCommunicateMsg(params).then((res) => {
          //console.log(res);
          res.forEach((item: any) => {
            idList.push(item.id);
          });
          communicateDataSource.value = res;
          const communicateDa = res;
          communicateParams.element = res.length;
          communicateParams.comment = communicateDa.comment;
          communicateParams.idList = idList;
          Object.assign(communicateData, communicateParams);
          console.log(communicateData);
          communicateVisible.value = true;
        });
      } else {
        const idList: Array<number> = [];
        state.selectedRows.forEach((item: SearchDataType) => {
          idList.push(item.id);
        });
        const params = { idList, ...getParams() };
        getCommunicateMsg(params).then((res) => {
          communicateDataSource.value = res;
          const communicateDa = res;
          communicateParams.element = res.length;
          communicateParams.comment = communicateDa.comment;
          Object.assign(communicateData, communicateParams);
          communicateVisible.value = true;
        });
      }
    };

    //确认邮件沟通
    const confirmCommunicate = () => {
      if (state.selectedRows.length === 0) {
        const idList = communicateParams.idList;
        const params = { idList, ...getParams() };
        communicateMail(params).then((res) => {
          closeCommunicateModal();
        });
      } else {
        const idList: number[] = [];
        state.selectedRows.forEach((item: SearchDataType) => {
          idList.push(item.id);
        });
        const params = { idList, ...getParams() };
        communicateMail(params).then((res) => {
          closeCommunicateModal();
        });
      }
    };

    //导出文件
    const exportFile = () => {
      if (state.selectedRows.length === 0) {
        const params = getParams();
        exportPageApi(params);
        state.selectedRows = [];
        getTableData();
      } else {
        const idList: number[] = [];
        state.selectedRows.forEach((item: SearchDataType) => {
          idList.push(item.id);
        });
        const params = { idList, ...getParams() };
        exportPageApi(params);
        state.selectedRows = [];
        getTableData();
      }
    };

    const tableWidth = columns.reduce((totalWidth: number, col: any) => {
      return totalWidth + col.width;
    }, 0);
    const colHeight = ref(500);
    const calculateHeight = () => {
      const table = document.getElementsByClassName('DemoCarTable')[0];
      const clinetHeight = document.body.clientHeight;
      const top = table.getBoundingClientRect().top;
      colHeight.value = clinetHeight - top - 130;
    };
    nextTick(() => {
      calculateHeight();
    });
    onMounted(() => {
      window.addEventListener('resize', calculateHeight);
    });

    //分页组件
    const pagination = reactive({
      total: 0,
      current: 1,
      pageSize: 20
    });
    //更改分页页签
    const pageChange = (page: number, size: number) => {
      pagination.current = page;
      pagination.pageSize = size;
      state.selectedRowKeys = [];
      getTableData();
    };
    //更改每页大小
    const sizeChange = (page: number, size: number) => {
      pagination.current = 1;
      pagination.pageSize = size;
      state.selectedRowKeys = [];
      getTableData();
    };
    //关闭confirm 弹窗
    const closeConfirmModal = () => {
      confirmVisible.value = false;
      state.selectedRows = [];
      getTableData();
    };
    //关闭batch confirm 弹窗
    const closebatchConfirmModal = () => {
      batchConfirmVisible.value = false;
      state.selectedRows = [];
      getTableData();
    };

    //关闭 communicate 弹窗
    const closeCommunicateModal = () => {
      communicateVisible.value = false;
      state.selectedRows = [];
      getTableData();
    };

    onMounted(() => {
      getTableData();
      getSelectStatus();
    });
    return {
      confirmStatusArray,
      ...toRefs(batchQuery),
      textNoArray,
      textContentNo,
      input,
      batchPlaceholder,
      batchQueryEvent,
      textChangEvent,
      closeBatchQueryModal,
      clearBatchQuery,
      searchData,
      moment,
      holdingPeriodChange,
      submissionChange,
      clickResetEvent,
      clickSearchEvent,
      showUploadAdjustedFile,
      communicateVisible,
      communicateDataSource,
      communicateData,
      communicateParams,
      communicate,
      exportFile,
      dataSource,
      onSelectChange,
      ...toRefs(state),
      columns,
      pagination,
      tableWidth,
      colHeight,
      pageChange,
      sizeChange,
      fileList,
      uploadAdjustedFile,
      clickConfirm,
      clickBatchConfirm,
      clickWithdraw,
      confirmVisible,
      batchConfirmVisible,
      batchConfirmData,
      batchDataSource,
      confirmData,
      closeConfirmModal,
      closebatchConfirmModal,
      getTableData,
      uploadVisible,
      closeUploadAdjusted,
      confirmParams,
      typeClassList,
      modelYearList,
      operationTimeChange,
      confirmCommunicate,
      closeCommunicateModal
    };
  }
});
