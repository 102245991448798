
import { message } from "ant-design-vue";
import { defineComponent, ref } from "vue";
import { batchConfirmDeadline } from "@/API/checking/demoCarClaim";
export default defineComponent({
  props: {
    batchConfirmVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    batchConfirmData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    batchDataSource: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const modal = (props as any).batchConfirmData;

    const columns = [
      {
        title: "Dealer Code",
        dataIndex: "dealerCode",
        width: 100,
      },
      {
        title: "VIN",
        dataIndex: "vin",
        width: 150,
      },
      {
        title: "Submission Deadline",
        dataIndex: "submissionDeadline",
        width: 150,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        width: 150,
      },
    ];

    const close = () => {
      emit("close");
    };

    const confirm = () => {
      if (modal.comment === "") {
        message.error("comment can't be null");
        close();
      } else {
        const params = {
          adjustedSubmissionDeadline: modal.adjustedSubmissionDeadline,
          comment: modal.comment,
          idList: modal.idList,
        };
        batchConfirmDeadline(params).then((res) => {
          close();
        });
      }
    };
    return {
      close,
      confirm,
      columns,
      modal,
    };
  },
});
